<template>
  <vue-perfect-scrollbar
    :settings="{suppressScrollX: true, wheelPropagation: false}"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%;"
  >
    <div class="pa-5 relative">
      <v-app-bar
        :color="$vuetify.theme.dark ? 'dark' : 'grey lighten-4'"
        :dark="$vuetify.theme.dark"
        class="pt-1"
        fixed
        flat
      >
        <div class="d-flex justify-space-between align-center w-full">
          <h6 class="ma-0">
            {{ $t('profile.myAccount') }}
          </h6>
          <slot name="userDrawerCloseButton" />
        </div>
      </v-app-bar>
    </div>
    <div
      v-if="b_IsSuperAdmin"
      class="pl-5 pt-5 pr-5 mt-5"
    >
      <div class="heading-label pa-0 mb-4">
        {{ $t('profile.superAdminProfil') }}
      </div>
      <div class="d-flex align-center">
        <div />
        <v-avatar
          size="56"
          class="mr-2"
        >
          <img
            width="50"
            height="50"
            src="@/assets/images/faces/1.jpg"
            alt=""
          >
        </v-avatar>
        <div>
          <a
            href="#"
            class="link-alt"
          >
            <p class="font-weight-medium ma-0 ">{{ getProfile.firstname }} {{ getProfile.lastname }}</p>
          </a>
        </div>
      </div>
      <div class="d-flex align-center mt-2">
        <v-icon small>
          mdi-chevron-right
        </v-icon>
        <p class="text-caption text--disabled ma-0">
          <router-link :to="`/${$i18n.locale}/admin-ms/sa/select-user`">
            {{ $t('profile.changeUser') }}
          </router-link>
        </p>
      </div>
      <div class="d-flex align-center mt-2">
        <v-icon small>
          mdi-chevron-right
        </v-icon>
        <p class="text-caption text--disabled ma-0">
          <router-link :to="`/${$i18n.locale}/app/forms/change-password`">
            {{ $t('profile.changePassword') }}
          </router-link>
        </p>
      </div>
    </div>
    <div class="pa-5 mt-5">
      <div class="heading-label pa-0 mb-4">
        Profil
      </div>
      <div class="d-flex align-center">
        <div />
        <v-avatar
          size="56"
          class="mr-2"
        >
          <v-icon color="primary" size=56>mdi-account-circle</v-icon>
        </v-avatar>
        <div>
          <a
            href="#"
            class="link-alt"
          >
            <p class="font-weight-medium ma-0 ">{{ getProfile.firstname }} {{ getProfile.lastname }}</p>
          </a>
          <p class="text-body-2 text--disabled mb-2">
            {{ $t('general.' + getCurrentRole.s_Role) }}
          </p>
          <!--
          <v-btn
            small
            icon
            color=""
          >
            <v-icon
              small
              dense
            >
              mdi-google
            </v-icon>
          </v-btn>
          <v-btn
            small
            icon
            color=""
          >
            <v-icon
              small
              dense
            >
              mdi-twitter
            </v-icon>
          </v-btn>
          <v-btn
            small
            icon
            color=""
          >
            <v-icon
              small
              dense
            >
              mdi-facebook
            </v-icon>
          </v-btn>
          -->
        </div>
      </div>
      <div class="d-flex align-center mb-10 mt-2">
        <v-icon small>
          mdi-chevron-right
        </v-icon>
        <p class="text-caption text--disabled ma-0">
          <router-link :to="`/${$i18n.locale}/app/forms/change-password`">
            Password ändern
          </router-link>
        </p>
      </div>
      <!--
      <div class="heading-label pa-0 mb-4">
        Fähigkeiten
      </div>
      <div class="mb-10">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="d-flex"
        >
          <v-avatar
            class="mr-2"
            :color="item.progressColor"
            size="36"
          >
            <span class="white--text text-20">{{
              item.letter
            }}</span>
          </v-avatar>
          <div class="flex-grow-1">
            <p class="font-weight-semi ma-0 ">
              {{ item.title }}
            </p>
            <p class="text--disabled text-caption">
              {{ item.subTitle }}
            </p>
          </div>
          <div class="flex-grow-1">
            <p class="ma-0 text-right">
              {{ item.progressbarText }}%
            </p>
            <v-progress-linear
              :color="item.progressColor"
              :value="item.progressvalue"
              rounded
            />
          </div>
        </div>
      </div>
      -->
    </div>
  </vue-perfect-scrollbar>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { isSuperAdmin } from '../../worker/worker-api'

  export default {
    name: 'UserDrawer',
    props: {},
    data () {
      return {
        b_IsSuperAdmin: false,
        items: [
          {
            letter: 'A',
            title: 'Angular',
            subTitle: 'Frontend framework',
            progressbarText: '90',
            progressvalue: 90,
            progressColor: 'danger',
          },
          {
            letter: 'V',
            title: 'Vue Js',
            subTitle: 'Frontend framework',
            progressbarText: '30',
            progressvalue: 30,
            progressColor: 'green',
          },
          {
            letter: 'R',
            title: 'React',
            subTitle: 'Frontend framework',
            progressbarText: '50',
            progressvalue: 50,
            progressColor: 'danger',
          },
          {
            letter: 'W',
            title: 'Wordpress Website',
            subTitle: 'CMS',
            progressbarText: '30',
            progressvalue: 30,
            progressColor: 'dark',
          },
        ],
      }
    },
    computed: {
      ...mapGetters(['getProfile', 'getCurrentRole']),
    },
    async beforeCreate () {
      this.b_IsSuperAdmin = await isSuperAdmin()
    },
    methods: {},
  }
</script>

<style scoped></style>
